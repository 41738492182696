import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-objetos',
  templateUrl: './objetos.page.html',
  styleUrls: ['./objetos.page.scss'],
})
export class ObjetosPage implements OnInit {

  type:any = "todos";
  user:string;  
  objetos:any = [];
  time_interval:any; 
  conect_desc:any = { conectados: [], desconectados: [], todos: this.objetos };
  token:any = null;

  constructor( public navCtrl: NavController, public _api: ApiService, private router: Router ) { 
    this.user  = localStorage.getItem("user");  
    this.token = localStorage.getItem("token_notificaicon");
    
  }

  ngOnInit() {

    if( this.token ){
      this._api.updateToken({ user: this.user, key: this.token }).subscribe((data:any)=>{
        console.log("update push", data);
      });
    }


    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log("Inicia objetos");
      }

      if (event instanceof NavigationEnd) {
          console.log("Termina objetos");
          if( this.time_interval ){     
            clearInterval( this.time_interval );
          }
          // Hide loading indicator
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }
    });
  }

  ionViewWillEnter(){  
    if( this.time_interval ){     
      clearInterval( this.time_interval );
    }
    this.getObjetos();
    this.time_interval = setInterval(()=>{
      
      this.getObjetos();
    }, 20000);
  }

  seguir(item:any){
    this.navCtrl.navigateForward(`seguir-objeto/${item.imei}`)
  }


  doRefresh(event:any){
    setTimeout(() => {
      console.log('Async operation has ended');
      this.getObjetos();
      event.target.complete();
    }, 2000);
  }

  testFunction( event:any ){
    console.log( event );
  }

  segmentChanged( $event:any ){
    console.log($event);
  }

  menuObjeto( objeto:any ){
    this.navCtrl.navigateForward(`menu-objeto/${objeto.imei}`);
  }

  getObjetos(){
    let data_send = { user: this.user };
    this._api.getObjetos(data_send).subscribe((data:any)=>{
      console.log(data);
      this.objetos = data.data;
      // this.conect_desc = 
      this.conteoConectDesc();
      
    });
  }

  conteoConectDesc(){   
    
    this.conect_desc.conectados = [];
    this.conect_desc.desconectados = [];

    if( this.objetos.length > 0 ){
      for( let objeto of this.objetos ){        
        if( this.conectadoDesc(objeto.timestamp) == 0 ){
          this.conect_desc.desconectados.push(objeto);
        }
        if( this.conectadoDesc(objeto.timestamp) == 1 ){
          this.conect_desc.conectados.push(objeto);
        }
      }

      this.conect_desc.todos = this.objetos;
      // return conect_desc;

    }
  }

  conectadoDesc( value:any ){
    if( value == 0 || value == undefined){
      return 2;
    }else{
      let time = new Date().valueOf();
      let res = time - value;
      if( res >= 600000 ){
        return 0;
      }else{
        return 1;
      }
    }
  }

}
